// extracted by mini-css-extract-plugin
export var searchContainer = "FaqSearch-module--searchContainer--1kLhZ";
export var heading = "FaqSearch-module--heading--1m1Sc";
export var answer = "FaqSearch-module--answer--2LT2a";
export var search = "FaqSearch-module--search--2xpYU";
export var question = "FaqSearch-module--question--WIfHF";
export var faq = "FaqSearch-module--faq--1_ny8";
export var backToHome = "FaqSearch-module--backToHome--3P37d";
export var backToHomeIcon = "FaqSearch-module--backToHomeIcon--3-SMl";
export var svg = "FaqSearch-module--svg--29aOV";
export var pagWrapper = "FaqSearch-module--pagWrapper--1kFzm";
export var pagNum = "FaqSearch-module--pagNum--9031O";
export var flipArrow = "FaqSearch-module--flipArrow--2aaZI";
export var active = "FaqSearch-module--active--2d6Bf";
export var qLink = "FaqSearch-module--qLink--3IODf";
export var searchTypeAhead = "FaqSearch-module--searchTypeAhead--XK7uM";
export var faqPageSearch = "FaqSearch-module--faqPageSearch--3Zw2E";