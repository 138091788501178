import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { useAlerts } from "../../context";
import { BaseLayout } from "../../components/Layouts/BaseLayout";
import { FluidSections } from "../../components/FluidSections";
import { FaqSearch } from "../../components/FAQSearch";

const Search = ({ data, pageContext, location }) => {
	const params = new URLSearchParams(location.search);
	const queryParams = params.get("q");

	/* Retrieve Page Data from the page query */
	const pageData = data.contentfulPage;
	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter, faqsData } = data;

	const { alertsCount } = useAlerts();

	let pageHeader = pageData.headerNavigation;
	let pageFooter = pageData.footer;

	if (!pageHeader) {
		pageHeader = defaultHeader;
	}

	if (!pageFooter) {
		pageFooter = defaultFooter;
	}
	const { categories } = faqsData?.edges?.[0]?.node;

	/* Use the FluidSection component to build out the fluid page sections */
	return pageData.noLayout ? (
		<FluidSections data={pageData.pageSections} location={location} locale={pageContext.locale} />
	) : (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			seo={pageData.seo}
			altLangPages={altLangPages}
			footerData={pageFooter}
			headerNavigation={pageHeader}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
			pageType={pageData?.pageType}
		>
			{queryParams && (
				<FaqSearch
					queryParams={queryParams}
					altLangPages={altLangPages}
					pageContext={pageContext}
					categories={categories}
				/>
			)}

			{pageData?.sections && (
				<FluidSections
					data={pageData.pageSections}
					location={location}
					locale={pageContext.locale}
				/>
			)}
		</BaseLayout>
	);
};

/* Declare page prop */
Search.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default Search;

/* Query page data using the pageSections GraphQL fragment included in the imported queryFluidSection file */
export const pageQuery = graphql`
	query GetSearchLayoutPageData(
		$id: String!
		$locale: String!
		$rclLocale: String!
		$ctf_id: String!
	) {
		contentfulPage(id: { eq: $id }) {
			title
			pageType
			sections {
				__typename
				...Collection
				...ComponentBlock
				...HardCodedComponent
				...LinkWithModal
				...ListLink
				...MultiImageBlock
				...Paragraph
				...PromotionRule
				...Section
				...SplitContentWrapper
				...TabsAndAccordion
				...TimedComponent
				...VideoPlayer
			}
			seo {
				...SEO
			}
			footer {
				...Footer
			}
			headerNavigation {
				...headerNavigation
			}
		}
		faqsData: allContentfulFaq(
			filter: {
				node_locale: { eq: $rclLocale }
				application: { elemMatch: { application3charCode: { eq: "SOV" } } }
			}
		) {
			edges {
				node {
					...faqsWithQAs
				}
			}
		}
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { ctf_id: { eq: $ctf_id } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
