import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../Icons";
import * as styles from "./FaqSearch.module.scss";

const FaqPagination = ({ data, contentPerPage, totalContent, setChunkIndex }) => {
	const [allowPrevious, setAllowPrevious] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [allowNext, setAllowNext] = useState(false);
	const [paginatedCards, setPaginatedCards] = useState([]);
	const prevCurrentPage = useRef();

	const totalCards = Math.ceil(data?.length / contentPerPage);

	const chunkedCards = [];

	let index = 0;
	for (let i = 0; i < data.length; i++) {
		chunkedCards.push(data.slice(index, contentPerPage + index));
		index += contentPerPage;
	}

	const handlePrevNextPageLogic = page => {
		if (currentPage > 1) {
			setAllowPrevious(true);
		} else {
			setAllowPrevious(false);
		}

		if (page === 1) {
			setAllowPrevious(false);
		} else {
			setAllowPrevious(true);
		}

		if (page < totalCards) {
			setAllowNext(false);
		} else {
			setAllowNext(true);
		}
		setChunkIndex(page - 1);
		setCurrentPage(page);
	};

	const setupPaginatedCards = () => {
		const pagination = [];
		const index = Math.floor(currentPage / contentPerPage - 0.1);
		const chunkedPaginated = [];

		let sectionIndex = 0;
		for (let i = 0; i < chunkedCards.length; i++) {
			chunkedPaginated.push(chunkedCards.slice(sectionIndex, contentPerPage + sectionIndex));
			sectionIndex += contentPerPage;
		}

		const chunkedPaginatedSections = [];
		const filteredChunk = chunkedPaginated.filter(x => x.length !== 0);
		for (let i = 0; i < filteredChunk.length; i++) {
			if (filteredChunk[i].filter(x => x.length !== 0).length !== 0) {
				chunkedPaginatedSections.push(filteredChunk[i].filter(x => x.length !== 0));
			}
		}

		for (let i = 1; i <= chunkedPaginatedSections[index].length; i++) {
			pagination.push({
				type: "page",
				number: index * contentPerPage + i,
				label: index * contentPerPage + i,
			});
		}

		setPaginatedCards(pagination);
		// handlePrevNextPageLogic(currentPage);
	};

	const handlePage = page => {
		handlePrevNextPageLogic(page);
	};
	const handleFirstPage = () => {
		if (currentPage > 1) {
			if (currentPage - 1 === 1) {
				setAllowPrevious(false);
			}
		}
		setCurrentPage(1);
		setChunkIndex(0);
	};
	const handlePrevPage = () => {
		if (currentPage > 1) {
			if (currentPage - 1 === 1) {
				setAllowPrevious(false);
			}
		}
		setChunkIndex(currentPage - 2);
		// setChunkIndex(currentPage);
		setCurrentPage(currentPage - 1);
	};
	const handleNextPage = () => {
		if (currentPage >= 1) {
			setAllowPrevious(true);
		}
		if (currentPage + 1 === totalCards) {
			setAllowNext(true);
		}
		setChunkIndex(currentPage);
		setCurrentPage(currentPage + 1);
	};
	const handleLastPage = () => {
		if (currentPage + 1 === totalCards) {
			setAllowNext(true);
		}
		if (currentPage >= 1) {
			setAllowPrevious(true);
		}
		setCurrentPage(totalCards);
		setChunkIndex(totalCards - 1);
	};

	useEffect(() => {
		prevCurrentPage.current = currentPage;

		if (paginatedCards.length === 0) {
			setupPaginatedCards();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginatedCards]);

	useEffect(() => {
		if (prevCurrentPage.current !== currentPage) {
			setupPaginatedCards();
		}
		if (currentPage < totalCards) {
			setAllowNext(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);
	return (
		<>
			{totalContent > 1 && (
				<div className={styles.pagWrapper}>
					<button
						onClick={handleFirstPage}
						disabled={!allowPrevious}
						type="button"
						className={styles.flipArrow}
					>
						<Icon name="arrow-double" />
					</button>
					<button onClick={handlePrevPage} disabled={!allowPrevious} type="button">
						<Icon name="arrow-left" />
					</button>
					{paginatedCards.map(pagination => (
						<button
							type="button"
							className={cx(`${currentPage === pagination?.number ? styles.active : ""}`)}
							label={pagination?.label}
							onClick={() => handlePage(pagination?.number)}
							disabled={currentPage === pagination?.number}
						>
							{pagination?.label}
						</button>
					))}
					<button onClick={handleNextPage} disabled={allowNext} type="button">
						<Icon name="arrow-right" />
					</button>
					<button onClick={handleLastPage} disabled={allowNext} type="button">
						<Icon name="arrow-double" />
					</button>
				</div>
			)}
		</>
	);
};

FaqPagination.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	contentPerPage: PropTypes.number,
	totalContent: PropTypes.number,
	setChunkIndex: PropTypes.func,
};

FaqPagination.defaultProps = {
	contentPerPage: null,
	totalContent: null,
	setChunkIndex: null,
};

export default FaqPagination;
