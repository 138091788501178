/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { Icon } from "../Icons";
import { Link } from "../Link";
import * as styles from "./FaqSearch.module.scss";
import FaqPagination from "./FaqPagination";
import getFaq, { getFaqData } from "./utils/getFaq";
import { getFaqSubCatURL } from "./utils/getFaqSubCatURL";
import { truncateString } from "./utils/truncateString";
import { RCL as useTranslation } from "../RCL";
import { FaqsTypeahead } from "../FaqsTypeahead";
import { Heading } from "../Heading";

const FaqSearch = ({ queryParams, pageContext, categories }) => {
	const backButtonLabel = useTranslation({
		searchKey: "faqs-home",
	});
	const searchResultForLabel = useTranslation({
		searchKey: "search-results-for",
	});

	const viewAllLabel = useTranslation({ searchKey: "faq-view-all" }) || "View all >>";

	const queryData = getFaq(categories)
		.filter(
			query =>
				(query && query?.question.toLowerCase().includes(queryParams.toLowerCase())) ||
				query?.contentString.toLowerCase().includes(queryParams.toLowerCase())
		)
		.sort((a, b) => b.order - a.order);
	const data = {
		no: queryData.length,
		searchKey: queryParams,
	};
	const [chunkIndex, setChunkIndex] = useState(0);
	const contentPerPage = 10;

	const totalCards = queryData.length / contentPerPage;
	const locale = pageContext?.locale?.substring(0, 2);
	const chunkedCards = [];
	let index = 0;
	for (let i = 0; i < totalCards; i++) {
		chunkedCards.push(queryData.slice(index, contentPerPage + index));
		index += contentPerPage;
	}

	const submitHandler = urlParams => {
		if (urlParams) navigate(`/${locale}/faqs/search/?q=${urlParams}`);
		else navigate(`/${locale}/faqs/search`);
	};

	return (
		<div className={styles.searchContainer}>
			<div className={styles.backToHome}>
				<Heading as="span" size="h3">
					<Link to={`/${locale}/faqs`}>
						<Icon name="arrow-left" className={styles.backToHomeIcon} />
						{backButtonLabel}
					</Link>
				</Heading>
			</div>

			<div className={styles.searchTypeAhead}>
				<FaqsTypeahead
					className={styles.faqPageSearch}
					data={getFaqData(categories)}
					isIcon={false}
					submitHandler={submitHandler}
					viewAllLink={viewAllLabel}
					locale={locale}
				/>
			</div>

			{queryData.length !== 0 ? (
				<>
					<Heading as="p" size="h1" className={styles.heading}>
						{data?.no} {searchResultForLabel} {`"${data?.searchKey}"`}
					</Heading>
					{chunkedCards[chunkIndex]?.map(item => (
						<Link
							to={`${getFaqSubCatURL(locale, item?.subCategory)}/#${item?.slug}`}
							className={styles?.qLink}
						>
							<div key={item.id} className={styles?.search}>
								<Heading as="p" size="sm" className={styles?.question}>
									{truncateString(item?.question, data?.searchKey, 60)}
								</Heading>
								<Heading as="p" size="sm" className={styles?.answer}>
									{truncateString(item?.contentString, data?.searchKey, 60)}
								</Heading>
							</div>
						</Link>
					))}
					{queryData?.length > contentPerPage && (
						<FaqPagination
							data={queryData}
							contentPerPage={contentPerPage}
							totalContent={queryData.length}
							setChunkIndex={setChunkIndex}
						/>
					)}
				</>
			) : (
				<Heading as="span" size="sm" className={styles.heading}>
					{`No results for ${queryParams}`}
				</Heading>
			)}
		</div>
	);
};

FaqSearch.propTypes = {
	queryParams: PropTypes.string,
	pageContext: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
};
FaqSearch.defaultProps = {
	queryParams: "",
};

export default FaqSearch;
export { FaqSearch };
